/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
    
		
		// Global Functions
		
		// Temp Vars
		var animationOption = false;
		
		// Carousel Scripts
		jQuery(".carousel").carousel();
	
	   	
	   	// Window Scroll Scripts
		var imgCount = 0;
		var scrollFlag = false;
		var headerOpen = true;
	  	var animating = false;
		var largeLogoWidth;
	  	var preHeaderHeight;
	  	var headerHeight;
		var pageHeaderHeight;
		var closeHeaderHeight;
	  	var bodyTopPadding;
	  	var navigationOffset;
	    var imageTopPosition; 
		var imageTopPositionStart;
		
	 	var animatedHeaderHeight;
	  	var animatedbodyTopPadding;
		
		var sPageURL;
	    var sURLVariables;
	    var sParameterName;
	    var i;
	    
		var navVisible = false;
		var showScrollButtonOffset = 1000; 
		
	    
		
		var BodyElement = jQuery('body, html');
		var isHome = (jQuery('.home')[0]) ? true : false;
		var isPage;
		if(isHome === false){
			isPage = (jQuery('.page-template')[0]) ? true : false;
		}
		
		
		function autoScrollSection(){
			var hasSection = false;
			var searchTerm = false;
			var sectionClass;
		
			var getUrlParameter = function getUrlParameter(sParam, sURL, externalLink) {
			    if(externalLink === true){
					sPageURL = sURL;
				    sURLVariables = sPageURL.split('&');
				    // sParameterName;
				    // i;
			    }else{
					sPageURL = sURL;
				    sURLVariables = sPageURL.split('?');
				    // sParameterName;
				    // i;
			    }
			    for (i = 0; i < sURLVariables.length; i++) {
			        sParameterName = sURLVariables[i].split('=');
	
			        if (sParameterName[0] === sParam) {
			            return sParameterName[1] === undefined ? true : sParameterName[1];
			        }
			    }
			};
			
			
			jQuery('.page-sub-menu ul.menu li a').each(function( index ) {
				var currentLink = jQuery(this).attr('href');
			    var linkSection = getUrlParameter('section', currentLink, false);
				jQuery(this).addClass('link_' + linkSection);
			});
			
			
			function setActiveLink(section) {
				jQuery('.page-sub-menu ul.menu li a').removeClass('active');
				jQuery('.page-sub-menu ul.menu li a').each(function( index ) {
					if( jQuery(this).hasClass('link_' + section) ){
						jQuery(this).addClass('active');
					}
				});
			}
	
	
			function animateBody(section, offset) {	
				if(jQuery('.' + section).length){
					var currentSection = jQuery('.' + section);
					var headerOffset = jQuery('.header-position-container').outerHeight();
					console.log('Sroll to' + currentSection.offset().top );
					var scrollLocation = currentSection.offset().top - (pageHeaderHeight + offset);// - (headerOffset + offset);
					jQuery("html, body").delay(800).animate({scrollTop: scrollLocation }, 'slow');
				}
			}
	
			if(isPage === true){
				var section = getUrlParameter('section', decodeURIComponent(window.location.search.substring(1)), true);
				var option = getUrlParameter('animationOption', decodeURIComponent(window.location.search.substring(1)), true);
			
				//console.log("section: " + section);
				//console.log("optioin: " + option);
			
				// temp
				if(option !== undefined && option === "true"){
					animationOption = true;
				}
	
				if(section !== undefined ){
					hasSection = true;
				}
			
				if(hasSection === true){
					console.log("has section");
					animateBody(section, 40);
					setActiveLink(section);
				}
			}
			
			
			
			// Event Listener Scripts	
			
			jQuery('.scroll-top-button').click(function(e) {
		    	e.preventDefault();
		        jQuery("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { 
		               // animation finished
		    	});
		    });
			
		
			jQuery('.page-sub-menu ul.menu li a').click(function(e) {	
			  	e.preventDefault();
				jQuery('.page-sub-menu ul.menu li a').removeClass('active');
				jQuery(this).addClass('active');
				var currentLink = jQuery(this).attr('href');
			    var linkSection = getUrlParameter('section', currentLink, false);
				console.log("section: " + linkSection);
			    animateBody(linkSection, 40); 
			});
			
		}
		
		
		// Mega Menu Scripts
		
		// START - Custom Slider Functions
	
	    var sliderAnimationHeight;
		var animationSpeed  = 400;
		var megaMenu = jQuery('.max-mega-menu');
		var menuWidgets = megaMenu.find('.mega-menu-item');
	    
	    function closeSlider(){
	    	menuWidgets.fadeOut(animationSpeed);
	        megaMenu.animate({'height':'hide'}, 100, function() {
	            jQuery(this).css("display", "");
				jQuery(".pre-header-container").css({
				    height: 'auto',
				    overflow: 'visible'
				});
	        });
	    }
		
	   
	    function openSlider(){
			jQuery(".pre-header-container").css({
				height: preHeaderHeight + 'px',
			    overflow: 'hidden'
			});
	        megaMenu.animate({'height':'show'}, '100', function() {
				headerContainerHeight = jQuery('.header-position-container').outerHeight();
				navHeight = jQuery('nav.navbar').outerHeight();
				sliderAnimationHeight = (headerContainerHeight + navHeight) - preHeaderHeight;
				homeHeaderCloseSliderAnimationHeight = (animatedHeaderHeight + navHeight);
				homeHeaderOpenSliderAnimationHeight = (animatedHeaderHeight + navHeight);
	            closeSlider();
	        });
		}
	    // END - Custom Slider Functions
		
		
		megaMenu.siblings(".mega-menu-toggle").on("click", function(e) {
			
			if ( jQuery(e.target).is(".mega-menu-toggle-block, .mega-toggle-blocks-left, .mega-toggle-blocks-center, .mega-toggle-blocks-right, .mega-toggle-label, .mega-toggle-label span") ) {
		                    
	            if (jQuery(this).hasClass("mega-menu-open")) {
					console.log('close');
	            	menuWidgets.fadeOut(animationSpeed); // added
					if(isHome === true && headerOpen === false){	
						jQuery('body').animate({'padding-top': animatedbodyTopPadding + 'px'}, animationSpeed); // added
					}else if(isHome === true && headerOpen === true){
	                	jQuery('body').animate({'padding-top': headerHeight + 'px'}, animationSpeed); // added
					}else{
	            		jQuery('body').animate({'padding-top': headerHeight + 'px'}, animationSpeed); // added
					}
	            } else {
					console.log('open');
	            	menuWidgets.fadeIn(animationSpeed); // added
					if(isHome === true && headerOpen === false){
						jQuery('body').animate({'padding-top': homeHeaderCloseSliderAnimationHeight}, animationSpeed); // added		
					}else if(isHome === true && headerOpen === true){
						console.log(sliderAnimationHeight);
						jQuery('body').animate({'padding-top': sliderAnimationHeight + 'px'}, animationSpeed); // added
					}else{
	            		jQuery('body').animate({'padding-top': sliderAnimationHeight + 'px'}, animationSpeed); // added
					}
	            }
	        }           
		});
		
		
		function adapt_scripts(){
			
		  	preHeaderHeight = parseInt(jQuery('.pre-header-container').outerHeight());
		  	headerHeight = parseInt(jQuery('.header-position-container').outerHeight());
			pageHeaderHeight = parseInt(jQuery('.header-position-container').outerHeight());
			largeLogoWidth= parseInt(jQuery('.site-logo a img').width());
			openHeaderHeight = headerHeight - preHeaderHeight;
		  	bodyTopPadding =  headerHeight;
		  	navigationOffset = parseInt(headerHeight);
			if(isHome === true){
				imageTopPosition = Math.abs(parseInt(jQuery("img.tall").offset().top));
				imageTopPositionStart = '-' + imageTopPosition + 'px';
			}
			animatedHeaderHeight = 94; //195
			animatedbodyTopPadding = (animatedHeaderHeight + preHeaderHeight);
			headerHeight = parseInt(jQuery('.header-position-container').outerHeight());
			jQuery("body").css('padding-top', headerHeight + 'px');
			
			openSlider();
			autoScrollSection();
			
			function animateHeader(state, animatedHeaderHeight, animatedbodyTopPadding){
				if(state === 'close' && animating === false ){
					console.log('close it');
					animating = true;
					headerOpen = false;
					
					jQuery("img.tall").css({
						top: imageTopPositionStart,
					    bottom: 'auto'
					});
					
					jQuery('header.banner img.hero').css({
						opacity: 0
					});
					
					jQuery("header.banner").stop().animate({
						height:animatedHeaderHeight + 'px',
						queue: false 
			
					}, 700, 'swing', function() { 
			            // animation finished
			    	});
				
					jQuery("body").stop().animate({
						paddingTop:animatedbodyTopPadding +'px',
						queue: false 
			
					}, 700, 'swing', function() { 
			            // animation finished
			    	});
					
					jQuery(".site-logo a img").stop().animate({
						width: "255px",
						marginTop: "3%"
			
					}, 700, 'swing', function() { 
						
					});
	
					jQuery("header.banner img.tall").stop().animate({
						top: 0 + "px"
			
					}, 700, 'swing', function() { 
			            // animation finished
						jQuery('html, body').css({
							position:'relative',
							top: 'auto',
							overflow: 'auto',
						    height: 'auto'
						});
						animating = false;
						scrollFlag = false;
			    	});
					
				}else if(state === 'open' && animating === false) { 
					console.log('open it');
					animating = true;
					headerOpen = true;
					jQuery("img.tall").css({
						top: 'auto'
					});
					
					//console.log('open Header to: ' + openHeaderHeight);
					//console.log('Body top-padding to: ' + bodyTopPadding);
					
					jQuery("header.banner").stop().animate({
						height:openHeaderHeight + 'px',  
						queue: false 
			
					}, 700, 'swing', function() { 
			            // animation finished
			    	});
				
					jQuery("body").stop().animate({
						paddingTop:bodyTopPadding +'px',
						queue: false 
			
					}, 700, 'swing', function() { 
			            // animation finished
			    	});
				
					jQuery('header.banner img.hero').css({
						opacity: 1
					});
				 	
					jQuery(".site-logo a img").stop().animate({
						width: largeLogoWidth + "px",
						marginTop: "10%"
			
					}, 700, 'swing', function() { 
						
					});
					
					jQuery("header.banner img.tall").stop().animate({
						bottom: 0 + 'px'
			
					}, 700, 'swing', function() { 
			            // animation finished
						jQuery('html, body').css({
							position:'relative',
							top: 'auto',
							overflow: 'auto',
						    height: 'auto'
						});
						animating = false;
			    	});
				}
			}
			
				
			if(isHome === true){
				setTimeout(function(){ 
					animateHeader('close', animatedHeaderHeight, animatedbodyTopPadding);
				}, 2000);
				
			}
			
			
			jQuery(window).resize( function() {
				if(isHome === true && headerOpen === true){
					animateHeader('close', animatedHeaderHeight, animatedbodyTopPadding);
				}
			});
			
		
			jQuery(window).scroll(function() {
		  
			    var windowOffset = Math.abs(parseInt(BodyElement.offset().top) - parseInt(jQuery(window).scrollTop()));
				preHeaderHeight = parseInt(jQuery('.pre-header-container').outerHeight());
				headerHeight = parseInt(jQuery('header .relative-container').outerHeight());
				openHeaderHeight = headerHeight;
				bodyTopPadding =  headerHeight + preHeaderHeight; 
				navigationOffset = parseInt(headerHeight);
			
			  	if(windowOffset > 10) {
				  if(isHome === true){
		  			if(headerOpen === true && animating === false ){
		  				// close
		  				headerOpen = false;
		  				jQuery('html, body').css({
		  					position:'absolute',
		  					top: '0px',
		  				    overflow: 'hidden',
		  				    height: '100%'
		  				});
		  				animateHeader('close', animatedHeaderHeight, animatedbodyTopPadding);
		  			}
		  			if (windowOffset > 350){
		  			  scrollFlag = true;
		  		    }
				  }else if ( isPage === true) {
	
				  }
				
			  	}else if (windowOffset < 10){
				  if (isHome === true) {
					  if(headerOpen === false && animating === false && scrollFlag === true){
						  // open
			  			headerOpen = true;
			  			jQuery('html, body').css({
							position:'absolute',
							top: '0px',
			  			    overflow: 'hidden',
			  			    height: '100%'
			  			});
			  			animateHeader('open', animatedHeaderHeight, animatedbodyTopPadding);
					  }
				  }else if ( isPage === true) {
	
				  }
				  
			  	}
				//console.log(showScrollButtonOffset);
				//console.log('Window offset: ' + windowOffset); 
				//console.log('Section offset: ' + showScrollButtonOffset);
				if(windowOffset > showScrollButtonOffset){
					if(navVisible === false){
						jQuery('.scroll-btn-container').stop().fadeIn(200);
						navVisible = true;
					}  
				}else{
					if(navVisible === true){
						jQuery('.scroll-btn-container').stop().fadeOut(200);
						navVisible = false;
					}   
				}
			});
		}
			
			
		function imageLoaded() {
		    imgCount++;
			console.log("Image loaded" + imgCount);  //Homepage
		    if (imgCount === 2) {
		        adapt_scripts();
		    }
			if(imgCount === 1 && isHome === false){   //Subpages
				adapt_scripts();
			}
		}
		
		jQuery("img.hero").one("load", function() {
			imageLoaded();
		}).each(function() {
		  if(this.complete) {
		  	jQuery(this).load();
		  }
		});
		
		jQuery("img.tall").one("load", function() {
			imageLoaded();
		}).each(function() {
		  if(this.complete) {
		  	jQuery(this).load();
		  }
		});
			
		// Colorbox Scripts
		// jQuery(".colorbox-custom").colorbox({inline:true, width:"90%"});
		
        // END - BaseKit Common Scripts
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
